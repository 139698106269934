export const UserRole = {
  ADMIN: 1,
  MAKERS: 2,
  DISTRIBUTIONS: 3,
  WHOLESALE: 4,
  REGISTER: 5,

  getName: (role) => {
    switch (role) {
      case UserRole.ADMIN: return '管理者'
      case UserRole.MAKERS: return 'メーカー'
      case UserRole.DISTRIBUTIONS: return '小売ブランド'
      case UserRole.WHOLESALE: return '卸'
      case UserRole.REGISTER: return 'レジ'
      default: return null
    }
  },
};

export const CouponStatus = {
  OPEN: 1,
  PRIVATE: 2,
  CLOSE: 3,
};

let serviceStartDate = Date('2020-01-01')
if (process.env.NODE_ENV == 'staging') {
  serviceStartDate = new Date('2020-07-01')
} else if (process.env.NODE_ENV == 'production') {
  serviceStartDate = new Date('2020-11-01')
}
export const ServiceStartDate = serviceStartDate