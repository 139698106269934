<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          class="text-white"
          v-bind:class="'bg-' + toast.level"
          :key="'toast' + index"
          :show="true"
        >
          {{ toast.message }}
        </CToast>
      </template>
    </CToaster>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-notes"/> ターゲットPUSH通知
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CInput
              label="タイトル"
              placeholder=""
              v-model="form.title"
              :isValid="checkIfValid('title')"
              invalidFeedback="入力してください。"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CTextarea
              label="本文"
              placeholder=""
              rows="5"
              v-model="form.body"
              :isValid="checkIfValid('body')"
              invalidFeedback="入力してください。"
            />
          </CCol>
        </CRow>
        <div v-if="this.isDistribution()">
          <div class="form-group form-row">
            <CCol tag="label" sm="3" class="col-form-label">
              <span style="padding-right: 10px;">対象店舗</span>
              <label>
                <input type="checkbox"
                  v-model="isCheckedAllStores"
                  @change="onChangedAllStores()"
                >すべて選択</label>
              <div class="validate-error-message" v-show="!this.isCheckedStores">
                1つ以上選択してください
              </div>
            </CCol>
            <CCol sm="9" class="form-inline">
              <div class="form-group" v-for="(item, index) in this.stores" v-bind:item="item" v-bind:index="index" v-bind:key="item.id">
                <input type="checkbox"
                  :id=item.id
                  :value=item.id
                  v-model=form.checkedStores
                  @change="onChangeStore()"
                />
                <label style="padding-right: 10px; padding-left: 5px;" :for=item.id>{{item.name}}</label>
              </div>
            </CCol>
          </div>
        </div>
        <div v-if="this.isMakers()">
          <div class="form-group form-row">
            <CCol tag="label" sm="3" class="col-form-label">
              対象小売企業
              <div class="validate-error-message" v-show="!this.isCheckedDistributions">
                1つ以上選択してください
              </div>
            </CCol>
            <CCol sm="9" class="form-inline">
              <div class="form-group" v-for="(item, index) in this.distributions" v-bind:item="item" v-bind:index="index" v-bind:key="item.id">
                <input type="checkbox"
                  :id=item.id
                  :value=item.id
                  v-model=form.checkedDistributions
                />
                <label style="padding-right: 10px; padding-left: 5px;" :for=item.id>{{item.name}}</label>
              </div>
            </CCol>
          </div>
        </div>
        <CRow>
          <CCol md="6">
            <CInput
              label="生年（開始）"
              placeholder="例: 1990"
              v-model="form.startYear"
              :lazy="true"
              :isValid="checkIfValid('startYear')"
              invalidFeedback="4桁の半角数字で入力してください。"
            />
          </CCol>
          <CCol md="6">
            <CInput
              label="生年（終了）"
              placeholder="例: 1991"
              v-model="form.endYear"
              :lazy="true"
              :isValid="checkIfValid('endYear')"
              invalidFeedback="4桁の半角数字で入力してください。"
            />
          </CCol>
        </CRow>
        <div class="form-group form-row">
          <CCol tag="label" sm="3" class="col-form-label">
            性別
          </CCol>
          <CCol sm="9" class="form-inline">
            <div class="form-group" v-for="val in sex" :key='val.key'>
              <label style="padding-right: 10px; padding-left: 5px;">
                <input type="checkbox" v-model="form.sex" :value='val.value'>
                {{ val.label }}
              </label>
            </div>
          </CCol>
        </div>
        <CRow>
          <CCol class="md-3">
            <CButton color="info" @click="confirmSendPushNotice">送信する</CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CModal
      title="PUSH通知送信"
      color="info"
      :show.sync="confirmSendPushNoticeModal"
      @ok="sendPushNotice()"
    >
      送信しますか？
      <template #footer>
        <CButton @click="confirmSendPushNoticeModal = false" color="secondary" :disabled="isSubmit">キャンセル</CButton>
        <CButton @click="sendPushNotice()" color="danger" :disabled="isSubmit">OK</CButton>
      </template>
    </CModal>

  </div>
</template>

<style>
  .validate-error-message {
    color: #e55353;
    font-size: 80%
  }
</style>

<script>
import { validationMixin } from "vuelidate"
import { required, numeric } from "vuelidate/lib/validators"
import Vue from 'vue';
import firebase from '@firebase/app';
import { uuid } from 'vue-uuid'
import { UserRole } from "@/common/const";

Vue.use(uuid);

const sex = [
  { key: "male", value: 1, label: "男性", },
  { key: "female", value: 2, label: "女性", },
  { key: "other", value: 3, label: "その他", },
];

export default {
  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },

    validateStores() {
      if (this.isDistribution()) {
        this.isCheckedStores = (this.form.checkedStores.length > 0)
      }
      return this.isCheckedStores
    },
    validateDistributions() {
      if (this.isMakers()) {
        this.isCheckedDistributions = (this.form.checkedDistributions.length > 0)
      }
      return this.isCheckedDistributions
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required
      },
      body: {
        required
      },
      startYear: {
        numeric
      },
      endYear: {
        numeric
      },
    }
  },

  data () {
    return {
      uid: "",
      user: {},
      account: {},
      toasts: [],
      stores: [],
      distributions: [],
      form: {
        title: "",
        body: "",
        startYear: "",
        endYear: "",
        sex: ['1', '2', '3'],
        checkedStores: [],
        checkedDistributions: []
      },
      isNew: true,
      confirmSendPushNoticeModal: false,
      sex,
      isCheckedStores: true,
      isCheckedDistributions: true,
      isSubmit: false,
      isCheckedAllStores: false
    }
  },

  methods: {
    async init () {
      // 初期化
      this.$store.commit("showLoading")
      await this.fetchUser()
      if (this.isDistribution) {
        await this.fetchStores()
      }
      if (this.isMakers) {
        await this.fetchDistributions()
      }
      this.$store.commit("hideLoading")
    },

    resetForm() {
      this.submitted = false
      this.form = {
        title: "",
        body: "",
        startYear: "",
        endYear: "",
        sex: ['1', '2', '3'],
        checkedStores: [],
        checkedDistributions: []
      }
      this.isCheckedAllStores = false
      this.$v.$reset()
    },

    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field.$model === '')
    },

    validate () {
      this.$v.$touch()
    },

    async fetchUser() {
      this.uid = firebase.auth().currentUser.uid
      const userSnapShot = await firebase.firestore()
        .collection("users")
        .doc(this.uid)
        .get();
      this.user = userSnapShot.data()
      let parent_id = userSnapShot.data().parent_id
      if (parent_id) {
        this.uid = parent_id
      }
    },

    async fetchStores() {
      const storeSnapshot = await firebase
        .firestore()
        .collection("stores")
        .where("created_user_id", "==", this.uid)
        .get();
      let tmpStores = []
      for (const storeDoc of storeSnapshot.docs) {
        let store = {}
        store.id = storeDoc.id
        store.storeCode = storeDoc.data().storeCode
        store.name = storeDoc.data().name
        tmpStores.push(store)
      }
      this.stores = tmpStores.sort((a, b) => Number(a.storeCode) - Number(b.storeCode))
    },

    async fetchDistributions() {
      const distributionsSnapshot = await firebase.firestore().collection('users').doc(this.uid).collection('distributions').get()
      for (const distributionDoc of distributionsSnapshot.docs) {
        const distributionUserSnapshot = await firebase.firestore().collection('users').doc(distributionDoc.id).get()
        let distribution = {}
        const distributionUser = distributionUserSnapshot.data()
        distribution.id = distributionDoc.id
        distribution.name = distributionUser.name

        const storesSnapshot = await firebase.firestore().collection('stores').where('companyCode', '==', distributionUser.companyCode).get()
        let stores = []
        for (const storeDoc of storesSnapshot.docs) {
          let store = {}
          store.id = storeDoc.id
          store.storeCode = storeDoc.data().storeCode
          store.name = storeDoc.data().name
          stores.push(store)
        }
        distribution.stores = stores.sort((a, b) => Number(a.storeCode) - Number(b.storeCode))
        if (stores.length > 0) {
          this.distributions.push(distribution)
        }
      }
    },

    confirmSendPushNotice() {
      this.$v.$touch()
      let isValidStores = this.validateStores
      let isValidDistributions = this.validateDistributions
      if (!this.isValid || !isValidStores || !isValidDistributions) {
        return
      }
      this.confirmSendPushNoticeModal = true
    },

    async sendPushNotice() {
      this.isSubmit = true
      this.$v.$touch()
      if (!this.isValid) {
        return
      }

      let id = uuid.v4()
      let saveData = JSON.parse(JSON.stringify(this.form))
      saveData.created_user_id = this.uid
      saveData.created = firebase.firestore.FieldValue.serverTimestamp();
      await firebase
        .firestore()
        .collection("targetSegments")
        .doc(id)
        .set(saveData)
      const event = firebase.app().functions('asia-northeast1').httpsCallable('sendUserPushNotice')
      await event({id: id})
      this.confirmSendPushNoticeModal = false
      this.resetForm()
      this.toasts.push({ level: "success", message: "送信しました。" });
      this.isSubmit = false
    },
    isDistribution() {
      return this.user.role == UserRole.DISTRIBUTIONS
    },
    isMakers() {
      return this.user.role == UserRole.MAKERS
    },
    onChangedAllStores() {
      if (this.isCheckedAllStores) {
        this.stores.forEach(s => this.form.checkedStores.push(s.id))
      } else {
        this.form.checkedStores = []
      }
    },
    onChangeStore() {
      this.isCheckedAllStores = (this.form.checkedStores.length == this.stores.length)
    }
  },
  created () {
    this.init()
  },
  watch: {
    '$route': function () {
      this.init()
    }
  },
}
</script>
